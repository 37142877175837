import React from "react";
import { GiFlyingFlag } from "react-icons/gi";
import { FaExternalLinkAlt } from "react-icons/fa";

const BrazilArea = () => {
  return (
    <>
      <section className="make-world-area hungarian-spring-area" id="spring">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 mx-auto">
              <div className="section-heading text-center">
                <div className="section-icon">
                  <GiFlyingFlag />
                </div>
                <h2 className="section__title">Next Stop: Brazil</h2>
                <p className="section__meta">
                  Support democratic participation on Brazilian elections
                </p>
              </div>
            </div>
          </div>

          <div className="row world-static-wrap2">
            <div className="col-lg-5">
              <div className="world-content">
                <h3 className="world__title">
                  Support civil society efforts to{" "}
                  <strong>register first-time voters.</strong>
                </h3>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="world-content">
                <p className="world__desc">
                  We are also looking to play a role supporting pro-democracy
                  grassroots forces in Brazil, another environment where the
                  rule of law has been under enormous stress and the 2022
                  election will be decisive. Our goal there is to support civil
                  society efforts who are working to register 16 and 17 year
                  olds to cast their first vote in Democracy's hour of need -
                  especially black youth who have been historically
                  disenfranchised.
                </p>
                <p className="world__desc mt-3">
                  We will also support efforts to encourage these new voters to
                  show up at the polls, as unlike most Brazilian citizens their
                  vote is not mandatory. We will also support efforts to ensure
                  seniors over 70 vote, as they are also not required to turn
                  out. But we know that many elder Brazilians have a very keen
                  memory of what it looks like when democracy is shut down, and
                  we are eager to help them defend it once again.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BrazilArea;
