import React, { useState, useEffect } from "react";
import Layout from "../components/Layout";
import NavOne from "../components/NavOne";
import Footer from "../components/Footer";
import SliderOne from "../components/SliderOne";
import Newsletter from "../components/Newsletter";
import HiwArea from "../components/HiwArea";
import HungarianSpringArea from "../components/HungarianSpringArea";
// import Popup from "../components/Popup";
import BrazilArea from "../components/BrazilArea";
import { useScrollPosition } from "../modules/scrollTracker";

const HomePage = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [scroll, setScroll] = useState(0);

  useEffect(() => {
    setIsOpen(true);
  }, []);

  useScrollPosition(function setScrollPosition({ currentPosition }) {
    setScroll(currentPosition.y);
  });

  return (
    <Layout>
      <NavOne />
      <SliderOne />
      <HungarianSpringArea />
      <HiwArea /> {/* Join our movement */}
      <BrazilArea />
      <Newsletter />
      <section className="notice-area hiw-area py-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-8 col-lg-6">
              <h2 className="text-center mb-5 mt-3 a4d-notification">
                With your help Action For Democracy funds frontline democracy
                organizations to broaden democratic participation and promote
                free and fair elections.
              </h2>
              <h3 className="text-center text-larger a4d-notification-small">
                {" "}
                We support nonpartisan voter registration drives and voter
                education especially in diaspora communities. We strengthen
                civil society and independent media through grants to combat
                hate, fear, and exclusion.
              </h3>
            </div>
          </div>
        </div>
      </section>
      <Footer />
      {/* {isOpen && <Popup toggle={togglePopup} />} */}
    </Layout>
  );
};

export default HomePage;
