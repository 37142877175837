import React from "react";
import { GiFlyingFlag } from "react-icons/gi";
import { FaExternalLinkAlt } from "react-icons/fa";

const HungarianSpringArea = () => {
  return (
    <>
      <section className="make-world-area hungarian-spring-area" id="spring">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 mx-auto">
              <div className="section-heading text-center">
                <div className="section-icon">
                  <GiFlyingFlag />
                </div>
                <h2 className="section__title">Hungarian Spring</h2>
                <p className="section__meta">
                  Join our first campaign to push back against autocrats in
                  Hungary
                </p>
              </div>
            </div>
          </div>

          <div className="row world-static-wrap2">
            <div className="col-lg-5">
              <div className="world-content">
                <h3 className="world__title">
                  Hungary is a <strong>battleground state</strong> in the global
                  fight to defend democracy.
                </h3>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="world-content">
                <p className="world__desc mt-5 mt-md-0 mb-5">
                  <strong>
                    Proceeds from our current fundraising drive fund the{" "}
                    <a
                      href="https://magyartavasz.hu"
                      target="_blank"
                      alt="magyartavasz.hu"
                    >
                      Hungarian Spring / Magyar Tavasz campaign{" "}
                      <FaExternalLinkAlt />
                    </a>
                    , helping Hungarians from the diaspora vote.
                  </strong>{" "}
                  <small className="text-uppercase">
                    Hungarian language content
                  </small>
                </p>
                <p className="world__desc">
                  Action for Democracy is a 501.(c)(4) that empowers democratic
                  actors to defend and rebuild their democracies. We are
                  committed to an inclusive and democratic future and we aim to
                  build and mobilize an international pro-democracy solidarity
                  movement at the grassroots that pushes back against the rising
                  threat of autocracy around the world.
                </p>
                <p className="world__desc mt-3">
                  Our first stop is in Hungary, a battleground state where
                  anti-democratic forces are trying to use an upcoming election
                  in April to cement their grip over power. Hungarian Prime
                  Minister Viktor Orban is the poster boy for kleptocratic
                  autocrats around the world,{" "}
                  <a
                    href="https://www.washingtonpost.com/politics/trump-offers-unusual-endorsement-of-hungarian-prime-minister-viktor-orban-ahead-of-parliamentary-elections/2022/01/03/23db1000-6c9b-11ec-b9fc-b394d592a7a6_story.html"
                    alt="Washignton Post article"
                  >
                    endorsed by Donald Trump <FaExternalLinkAlt />{" "}
                  </a>
                  , supported by Vladimir Putin, and trumpeted by Tucker
                  Carlson. He has emboldened corruption that has enriched his
                  cadre at the expense of ordinary Hungarians and laid out a
                  welcome mat to Vladimir Putin in Europe.{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <section className="candidate-quote-area">
        <div className="container">
          <div className="row">
            <div className="col-10">
              <div>
                <h2 className="section__title">
                  <i>
                    "We now have the highest chance in a decade to defeat Orbán"
                  </i>
                </h2>
                <p className="section__desc">
                  <i>- Márki-Zay Péter, opposition candidate</i>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section> */}
    </>
  );
};

export default HungarianSpringArea;
