import React, { useState } from "react";
import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import EmbedVideo from "./EmbedVideo";
import Video from "./Video";

const SliderOne = () => {
  const [swiper, setSwiper] = useState(null);
  // const [controlledSwiper, setControlledSwiper] = useState(null);
  // const goNext = () => {
  //   if (swiper !== null) {
  //     swiper.slideNext();
  //   }
  // };

  // const goPrev = () => {
  //   if (swiper !== null) {
  //     swiper.slidePrev();
  //   }
  // };

  return (
    <section className="slider-area">
      <div className="homepage-slide1">
        <Swiper
          onSwiper={setSwiper}
          modules={[Autoplay]}
          autoplay={{ delay: 5000 }}
        >
          <SwiperSlide className="single-slide-item slide-bg1">
            <div className="slide-item-table">
              <div className="slide-item-tablecell">
                <div className="container">
                  <div className="row">
                    <div className="col-md-8 col-lg-6">
                      <div className="slider-heading">
                        <p className="slider__meta">United for Democracy</p>
                        <h2 className="slider__title">
                          Join our movement to support free and democratic
                          elections
                        </h2>
                        <div className="d-block">
                          <ul>
                            <li className="mb-2">
                              <a
                                href="https://secure.actblue.com/donate/a4d-website?refcode=website&amount=25"
                                target="_blank"
                                rel="noreferrer noopener"
                                className="theme-btn theme-btn-2"
                              >
                                donate now
                              </a>
                            </li>
                            <li>
                              <a
                                href="https://act.actionfordemocracy.org/fundraising/donate-hungarian-spring/"
                                target="_blank"
                                rel="noreferrer noopener"
                                className="theme-btn theme-btn-hu"
                              >
                                Hungarian? donate here
                              </a>
                            </li>
                          </ul>
                          {/* <a
                            href="https://secure.actblue.com/donate/a4d-website?refcode=website&amount=25"
                            target="_blank"
                            rel="noreferrer noopener"
                            className="theme-btn theme-btn-2 theme-btn-main"
                          >
                            donate now
                          </a> */}
                        </div>
                        <div className="d-inline mr-5">
                          <a
                            href="/#joinus"
                            className="theme-btn theme-btn-2 theme-btn-main"
                          >
                            ways to engage
                          </a>
                        </div>
                        <div className="d-inline">
                          <a
                            href="/mission/#mission"
                            className="theme-btn theme-btn-3 theme-btn-main"
                          >
                            discover more
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-lg-6 d-flex mt-5 mt-md-0">
                      <Video />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
        {/* <div className="owl-dots">
          <div
            role="button"
            onClick={goPrev}
            onKeyDown={goPrev}
            tabIndex="0"
            className="owl-dot"
          >
            <span></span>
          </div>
          <div
            role="button"
            onClick={goNext}
            className="owl-dot"
            onKeyUp={goNext}
            tabIndex="0"
          >
            <span></span>
          </div>
        </div> */}
      </div>
    </section>
  );
};

export default SliderOne;
