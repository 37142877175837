import React from "react";
import VideoMp4 from "../video/a4d_long_video.mp4";
// import VideoWebm from "../video/a4d_long_video.webm";
import posterImg from "../images/logo_a4d_white_wide.png";

const Video = ({ videoSrcURL, videoTitle, ...props }) => (
  <div className="video-holder w-100 my-auto">
    <video controls height="320" width="100%" poster={posterImg}>
      <source src={VideoMp4} type="video/mp4" />
      {/* <source src={VideoWebm} type="video/webm" /> */}
    </video>
  </div>
);
export default Video;
